import { useEffect, useState } from "react";
import {
  AutoComplete,
  Input,
  Layout,
  Form,
  Button,
  Upload,
  Switch,
  Collapse,
  Table,
  Image,
  PageHeader,
  Typography,
  Row,
  Col,
  Space,
} from "antd";
import { RcFile } from "antd/lib/upload";

import {
  getResource,
  postFormData,
  updateResource,
  useDataResource,
} from "src/api";
import { PlusOutline } from "styled-icons/evaicons-outline";
import { Facebook } from "@styled-icons/entypo-social/Facebook";
import { Youtube } from "@styled-icons/entypo-social/Youtube";
import { Twitter } from "@styled-icons/entypo-social/Twitter";
import { Pinterest } from "@styled-icons/entypo-social/Pinterest";
import { Instagram } from "@styled-icons/entypo-social/Instagram";
import { Tiktok } from "@styled-icons/simple-icons/Tiktok";

const { Content } = Layout;
const { Panel } = Collapse;
const { Title } = Typography;

export interface InternalCompetitorJson {
  active: boolean;
  checked: boolean;
  shop_id?: number;
  competitorLogo?: string;
  competitorName: string;
  facebookPageId?: string;
  facebookPageUrl?: string;
  id: number;
  website: string;
  appUser: boolean;
  importDone: boolean;
  billingPlan?: string;
  popupDiscount?: string;
}

export interface CompetitorSocialsJson {
  id: number;
  url: string;
  platform: string;
}

const platformSocials: { [k: string]: CompetitorSocialsJson } = {
  facebook: { id: -1, url: "", platform: "" },
  twitter: { id: -1, url: "", platform: "" },
  pinterest: { id: -1, url: "", platform: "" },
  tiktok: { id: -1, url: "", platform: "" },
  instagram: { id: -1, url: "", platform: "" },
  youtube: { id: -1, url: "", platform: "" },
};

const NOT_ALLOWED_BILLING_PLANS = ["free", "advanced", "advanced_annual", "advanced_minor", "advanced_minor_annual", "advanced_50_off"];

export const CompetitorsEdit: React.FC = () => {
  const [selected, setSelected] = useState<InternalCompetitorJson>();
  const [logo, setLogo] = useState<RcFile>();
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [saving, setSaving] = useState(false);
  const [socials, setSocials] = useState(platformSocials);
  const [socialsLoading, setSocialsLoading] = useState<{
    [k: string]: boolean;
  }>({});

  const { data, mutate } =
    useDataResource<InternalCompetitorJson[]>("competitors/all");
  const [form] = Form.useForm<InternalCompetitorJson>();

  useEffect(() => {
    if (data && selected) {
      data.forEach((c) => {
        if (c.id === selected.id) {
          setSelected(c);
        }
      });
    }
  }, [data]);

  const getSocials = async (c: InternalCompetitorJson) => {
    const response = await getResource<CompetitorSocialsJson[]>(
      `competitors/${c.id}/socials`
    );
    let soc = { ...platformSocials };

    response.forEach((s) => {
      soc[s.platform] = s;
    });

    setSocials({ ...soc });
  };

  useEffect(() => {
    selected && form.setFieldsValue(selected);
    selected && getSocials(selected);
  }, [selected]);

  const saveCompetitor = async (c: InternalCompetitorJson) => {
    setSaving(true);
    const response = await updateResource<
      InternalCompetitorJson,
      InternalCompetitorJson
    >(`competitors/${c.id}`, "PATCH", c);

    if (response) {
      mutate();
    }

    setSaving(false);
  };

  const saveCompetitorSocial = async (
    c: InternalCompetitorJson,
    s: CompetitorSocialsJson
  ) => {
    setSocialsLoading((prev) => ({ ...prev, [s.platform]: true }));
    if (s.id === -1) {
      await updateResource<CompetitorSocialsJson, CompetitorSocialsJson>(
        `competitors/${c.id}/socials`,
        "POST",
        s
      );
    } else {
      await updateResource<CompetitorSocialsJson, CompetitorSocialsJson>(
        `competitors/${c.id}/socials/${s.id}`,
        "PATCH",
        s
      );
    }

    getSocials(c);

    setSocialsLoading((prev) => ({ ...prev, [s.platform]: false }));
  };

  return (
    <Layout>
      <PageHeader
        title="Edit competitors"
        subTitle="All things competitors"
        extra={[
          <AutoComplete
            key="1"
            dropdownMatchSelectWidth={252}
            style={{ width: 300 }}
            options={data ? data.map((d) => ({ value: d.competitorName })) : []}
            onSelect={(v: string) =>
              setSelected(data?.find((c) => c.competitorName === v))
            }
            onSearch={(v) => { }}
            filterOption={(inp, option) =>
              option && option.value ? option.value.startsWith(inp) : false
            }
          >
            <Input.Search
              size="large"
              placeholder="Search competitors"
              enterButton
            />
          </AutoComplete>,
        ]}
      />
      <Content style={{ padding: 32 }}>
        <Collapse defaultActiveKey={["1"]} style={{ marginBottom: 32 }}>
          <Panel
            header={`Unchecked competitors (${data?.filter((c) => !c.checked && c.billingPlan && !NOT_ALLOWED_BILLING_PLANS.includes(c.billingPlan)).length
              })`}
            key="1"
          >
            <Table
              style={{ cursor: "pointer" }}
              onRow={(record) => {
                return {
                  onClick: () => setSelected(record),
                };
              }}
              pagination={{ pageSize: 5 }}
              dataSource={data?.filter((c) => !c.checked && c.billingPlan && !NOT_ALLOWED_BILLING_PLANS.includes(c.billingPlan))}
              columns={[
                {
                  title: "Id",
                  dataIndex: "id",
                  key: "id",
                  width: 150,
                },
                {
                  title: "Name",
                  dataIndex: "competitorName",
                  key: "name",
                  sorter: (a, b) =>
                    a.competitorName < b.competitorName ? 1 : -1,
                },
                {
                  title: "Shop Id",
                  dataIndex: "shopId",
                  key: "shopId",
                },
                {
                  title: "Active",
                  dataIndex: "active",
                  key: "active",
                  render: (val) => (val ? <>&#10003;</> : <>&#8855;</>),
                },
                {
                  title: "Billing Plan",
                  dataIndex: "billingPlan",
                  key: "billingPlan"
                }
              ]}
            />
          </Panel>
        </Collapse>

        {selected && (
          <>
            <Row>
              <Col span={16}>
                <Title level={4}>Competitor data</Title>

                <Form
                  labelCol={{ span: 5 }}
                  wrapperCol={{ span: 20 }}
                  form={form}
                  name="competitor-form"
                  layout="horizontal"
                  onFinish={(v) => saveCompetitor(v)}
                  initialValues={selected}
                >
                  <Form.Item name="id" label="Id">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="competitorName"
                    label="Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item name="facebookPageId" label="Facebook page id">
                    <Input
                      onChange={(e) =>
                        e.currentTarget.value === "" &&
                        form.setFieldValue("facebookPageId", null)
                      }
                    />
                  </Form.Item>

                  <Form.Item name="facebookPageUrl" label="Facebook page url">
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="website"
                    label="Website"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="popupDiscount"
                    label="Popup discount"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item label="Shop Id" name="shopId">
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Checked"
                    valuePropName="checked"
                    name="checked"
                  >
                    <Switch disabled />
                  </Form.Item>

                  <Form.Item
                    label="Active"
                    valuePropName="checked"
                    name="active"
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 16 }}
                      loading={saving}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={2} />

              <Col span={6}>
                <Title level={4}>Upload logo</Title>

                <Image
                  src={selected.competitorLogo}
                  height={100}
                  alt="Logo"
                  preview={false}
                />

                <Upload
                  listType="picture-card"
                  multiple={false}
                  maxCount={1}
                  beforeUpload={(file) => {
                    setLogo(file);
                    return false;
                  }}
                >
                  <div>
                    <PlusOutline width={24} />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>

                <Button
                  type="primary"
                  style={{ marginTop: 16 }}
                  loading={uploadingLogo}
                  onClick={async () => {
                    const data = new FormData();

                    if (logo) {
                      data.append("file", logo as Blob);
                      setUploadingLogo(true);

                      const resp = await postFormData<string>(
                        `competitors/upload-logo/${selected.id}`,
                        data
                      );

                      if (resp) {
                        mutate();
                      }

                      setUploadingLogo(false);
                    }
                  }}
                >
                  Set logo
                </Button>
              </Col>
            </Row>
            {/* <Row>
              <Col span={8}>
                <Title level={4}>Competitor social pages</Title>
                <Row style={{ marginBottom: 16 }}>
                  <Space style={{ marginBottom: 8 }}>
                    <Facebook style={{ width: 24 }} />
                    Facebook
                  </Space>
                  <Input.Group compact>
                    <Input
                      style={{ width: "calc(100% - 85px)" }}
                      value={socials.facebook.url}
                      onChange={(e) =>
                        setSocials((prev) => ({
                          ...prev,
                          facebook: {
                            ...socials.facebook,
                            url: e.currentTarget.value,
                            platform: "facebook",
                          },
                        }))
                      }
                    />
                    <Button
                      type="primary"
                      loading={socialsLoading.facebook}
                      onClick={() =>
                        saveCompetitorSocial(selected, socials.facebook)
                      }
                    >
                      Save
                    </Button>
                  </Input.Group>
                </Row>
                <Row style={{ marginBottom: 16 }}>
                  <Space style={{ marginBottom: 8 }}>
                    <Instagram style={{ width: 24 }} /> Instagram
                  </Space>
                  <Input.Group compact>
                    <Input
                      style={{ width: "calc(100% - 85px)" }}
                      value={socials.instagram.url}
                      onChange={(e) =>
                        setSocials((prev) => ({
                          ...prev,
                          instagram: {
                            ...socials.instagram,
                            url: e.currentTarget.value,
                            platform: "instagram",
                          },
                        }))
                      }
                    />
                    <Button
                      type="primary"
                      loading={socialsLoading.instagram}
                      onClick={() =>
                        saveCompetitorSocial(selected, socials.instagram)
                      }
                    >
                      Save
                    </Button>
                  </Input.Group>
                </Row>
                <Row style={{ marginBottom: 16 }}>
                  <Space style={{ marginBottom: 8 }}>
                    <Youtube style={{ width: 24 }} /> YouTube
                  </Space>
                  <Input.Group compact>
                    <Input
                      style={{ width: "calc(100% - 85px)" }}
                      value={socials.youtube.url}
                      onChange={(e) =>
                        setSocials((prev) => ({
                          ...prev,
                          youtube: {
                            ...socials.youtube,
                            url: e.currentTarget.value,
                            platform: "youtube",
                          },
                        }))
                      }
                    />
                    <Button
                      type="primary"
                      loading={socialsLoading.youtube}
                      onClick={() =>
                        saveCompetitorSocial(selected, socials.youtube)
                      }
                    >
                      Save
                    </Button>
                  </Input.Group>
                </Row>
                <Row style={{ marginBottom: 16 }}>
                  <Space style={{ marginBottom: 8 }}>
                    <Tiktok style={{ width: 24 }} /> TikTok
                  </Space>
                  <Input.Group compact>
                    <Input
                      style={{ width: "calc(100% - 85px)" }}
                      value={socials.tiktok.url}
                      onChange={(e) =>
                        setSocials((prev) => ({
                          ...prev,
                          tiktok: {
                            ...socials.tiktok,
                            url: e.currentTarget.value,
                            platform: "tiktok",
                          },
                        }))
                      }
                    />
                    <Button
                      type="primary"
                      loading={socialsLoading.tiktok}
                      onClick={() =>
                        saveCompetitorSocial(selected, socials.tiktok)
                      }
                    >
                      Save
                    </Button>
                  </Input.Group>
                </Row>
                <Row style={{ marginBottom: 16 }}>
                  <Space style={{ marginBottom: 8 }}>
                    <Pinterest style={{ width: 24 }} /> Pinterest
                  </Space>
                  <Input.Group compact>
                    <Input
                      style={{ width: "calc(100% - 85px)" }}
                      value={socials.pinterest.url}
                      onChange={(e) =>
                        setSocials((prev) => ({
                          ...prev,
                          pinterest: {
                            ...socials.pinterest,
                            url: e.currentTarget.value,
                            platform: "pinterest",
                          },
                        }))
                      }
                    />
                    <Button
                      type="primary"
                      loading={socialsLoading.pinterest}
                      onClick={() =>
                        saveCompetitorSocial(selected, socials.pinterest)
                      }
                    >
                      Save
                    </Button>
                  </Input.Group>
                </Row>
                <Row style={{ marginBottom: 16 }}>
                  <Space style={{ marginBottom: 8 }}>
                    <Twitter style={{ width: 24 }} />
                    Twitter
                  </Space>
                  <Input.Group compact>
                    <Input
                      style={{ width: "calc(100% - 85px)" }}
                      value={socials.twitter.url}
                      onChange={(e) =>
                        setSocials((prev) => ({
                          ...prev,
                          twitter: {
                            ...socials.twitter,
                            url: e.currentTarget.value,
                            platform: "twitter",
                          },
                        }))
                      }
                    />
                    <Button
                      type="primary"
                      loading={socialsLoading.twitter}
                      onClick={() =>
                        saveCompetitorSocial(selected, socials.twitter)
                      }
                    >
                      Save
                    </Button>
                  </Input.Group>
                </Row>
              </Col>
            </Row> */}
          </>
        )}
      </Content>
    </Layout>
  );
};
